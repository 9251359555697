import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { accountRoutes } from 'helpers/routingHelper';
import { useUser } from 'state/user/state';

const UserLoggedInWrapper: React.FC = ({ children }) => {
  const [user] = useUser();

  useEffect(() => {
    if (user?.isLoggedIn === false) {
      navigate(accountRoutes.login);
    }
  }, [user]);

  return (
    <>
      {!user?.isLoggedIn && <LoadingOverlay loadingMessage="Loading page" />}
      {user?.isLoggedIn && children}
    </>
  );
};

export default UserLoggedInWrapper;
