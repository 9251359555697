import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import UserLoggedInWrapper from 'components/UserLoggedInWrapper';
import { accountRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';
import { StyledHeading, StyledPrimaryCta, Subhead } from './styles';

export const query = graphql`
  {
    csTierInvalidError {
      meta {
        meta_title
      }
      heading
      subheading
      account_button_text
    }
  }
`;

type Content = {
  csTierInvalidError: {
    meta: CsMeta;
    heading: string;
    subheading: string;
    account_button_text: string;
  };
};

const IneligibleTierMtaErrorPage: React.FC = () => {
  const { csTierInvalidError } = useStaticQuery<Content>(query);

  const [user] = useUser();
  usePageTracking(csTierInvalidError.meta.meta_title, !!user?.isLoggedIn);

  return (
    <UserLoggedInWrapper>
      <Layout meta={csTierInvalidError.meta} pageType="mtaAndRenewal">
        <StyledHeading>{csTierInvalidError.heading}</StyledHeading>
        <Subhead>{csTierInvalidError.subheading}</Subhead>
        <StyledPrimaryCta
          cta={{
            displayText: csTierInvalidError.account_button_text,
            screenReaderText: csTierInvalidError.account_button_text,
            onClick: () => {
              navigate(accountRoutes.account);
            },
          }}
        />
        <FullWidthDivider />
      </Layout>
    </UserLoggedInWrapper>
  );
};

export default IneligibleTierMtaErrorPage;
